<app-header></app-header>

<section class="product-page-banner">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="top-banner-wrapper">
            <a [routerLink]="">
              <img src="assets/images/product/product-page-banner.png" class="img-fluid " alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- section start -->
<section class="product-page">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
            <!-- side-bar colleps block stat -->
            <div class="collection-filter-block">
                <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                    <span class="filter-back">
                      <i class="fa fa-angle-left" aria-hidden="true"></i> back
                    </span>
                </div>
                <!-- Category filter start -->
                <app-category></app-category>
            </div>
            <!-- silde-bar colleps block end here -->
            <!-- side-bar colleps block stat -->
            <div class="collection-filter-block">
                <!-- price filter start here -->
                <app-price [min]="minPrice" [max]="maxPrice" (priceFilter)="updateFilter($event)">
                </app-price>
                <div class="collection-collapse-block">
                  <h3 class="collapse-block-title">Stock Status</h3>
                  <div class="collection-collapse-block-content">
                    <div class="color-selector">
                      <div class="d-flex">
                        <input class="form-check-input" style="background-color: transparent;" type="checkbox" value="" id="flexCheckIndeterminate">
                        <span style="margin-left: 5px;">In Stock</span>
                        <span style="margin-left: auto;">(17)</span>
                      </div>
                      <br>
                      <div class="d-flex">
                        <input class="form-check-input" style="background-color: transparent;" type="checkbox" value="" id="flexCheckIndeterminate">
                        <span style="margin-left: 5px;">Out of stock</span>
                        <span style="margin-left: auto;">(3)</span>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <!-- silde-bar colleps block end here -->
            <!-- side-bar banner start here -->
            <div class="collection-sidebar-banner">
              <a [routerLink]="">
                <img src="assets/images/productpage_left_banner.png" class="img-fluid" alt="banner">
              </a>
            </div>
            <!-- side-bar banner end here -->
        </div>
        </div>
        <div class="collection-content col">
          <div class="page-main-content">
            <div class="row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-xl-12">
                      <div class="filter-main-btn" (click)="toggleMobileSidebar()">
                        <span class="filter-btn btn btn-theme">
                          <i class="fa fa-filter" aria-hidden="true"></i> Filter
                        </span>
                      </div>
                  </div>
                </div>
                <ul class="product-filter-tags">
                  <li class="me-1" *ngFor="let tag of tags">
                    <a href="javascript:void(0)" class="filter_tag">
                      {{tag}} <i class="ti-close" (click)="removeTag(tag)"></i>
                    </a>
                  </li>
                  <li class="clear_filter" *ngIf="tags.length > 0">
                    <a href="javascript:void(0)" class="clear_filter" (click)="removeAllTags()">
                      Clear all
                    </a>
                  </li>
                </ul>
                <!-- Tab product start-->
                <div class="collection-product-wrapper" id="products">
                  <div class="proHeader">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-4 theme-heading">
                          <div class="">
                            <h3>Vapes</h3>
                          </div>
                        </div>
                        <div class="col theme-tab">
                          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="tabs tab-title justify-content-center nav nav-pills">
                            <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
                              <a ngbNavLink>
                                {{ collection }}
                              </a>
                              <ng-template ngbNavContent>
                                <div class="no-slider no-slider-product-page row">
                                  <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:9">
                                    <div class="product-box">
                                      <div class="img-wrapper">
                                        <div class="lable-block" *ngIf="product.new">
                                          <span class="lable3">new</span>
                                        </div>
                                        <div class="front">
                                          <a [routerLink]="['/pages/home/', product.title.replace(' ', '-')]">
                                            <img  [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'"
                                                  [lazyLoad]="product.images[0].src" class="img-product"
                                                  alt="{{ product.images[0].alt }}">
                                          </a>
                                        </div>
                                      </div>
                                      <div class="product-info">
                                        <a [routerLink]="['/pages/home/', product?.title.replace(' ', '-')]">
                                          <h6>{{ product?.title | titlecase }}</h6>
                                        </a>
                                        <h4 style="color: #d59a36;">
                                            5500 PKR
                                          <del><span class="money"> 7500 PKR</span></del>
                                        </h4>
                                        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                                      </div>
                                      <div class="add-to-cart">
                                        <a [routerLink]="['add-to-cart']">
                                          <img src="assets/images/icon/product-cart.svg" alt="Shopping">
                                          <span>Add To Cart</span>
                                        </a>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                              </ng-template>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Tab product End -->
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 text-center">
                <button type="button" class="btn btn-show-more-products">Show more</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- section End -->

<app-footer></app-footer>