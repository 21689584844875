import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-header-five',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() class: string = 'header-2';
  @Input() themeLogo: string = 'assets/images/logos/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  themeText: string = 'Light Theme';

  constructor() { }

  ngOnInit(): void {
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  customizeLayoutDark() {
    // Toggle the text between "Light Theme" and "Dark Theme"
    this.themeText = this.themeText === 'Light Theme' ? 'Dark Theme' : 'Light Theme';
    document.body.classList.toggle('dark')
  }

}
