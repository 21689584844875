<!--footer section -->
<footer [class]="class">
	<div class="subscribe-banner p-left light-layout" *ngIf="newsletter" [ngStyle]="{'background-image': 'url(assets/images/banner/inner-banner.png)'}">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h5>Our newsletter</h5>
          						<h4>Get the updated news</h4>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Email...">
							</div>
							<button type="submit" class="btn btn-solid-subscribe">
								<!-- <i class="fa fa-paper-plane" aria-hidden="true"></i>  -->
								<img src="assets/images/icon/plane-1.svg" alt="Subscribe">
								subscribe now
							</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="light-layout footer-section">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>Delve into a realm of premium flavors and unmatched quality. Join our community for exclusive updates, offers, and more.
						Experience tranquility with every puff.
						</p>
						<br>
						<p>Connect with us:</p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="">
										<!-- <i class="fa fa-facebook" aria-hidden="true"></i> -->
										<img src="assets/images/icon/fb-footer.svg" alt="Facebook">
									</a>
                                </li>
                                <li>
                                    <a href="">
										<!-- <i class="fa fa-instagram" aria-hidden="true"></i> -->
										<img src="assets/images/icon/insta-footer.svg" alt="Instagram">
									</a>
                                </li>
                                <li>
                                    <a href="">
										<!-- <i class="fa fa-linkedin" aria-hidden="true"></i> -->
										<!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
											<path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
										</svg> -->
										<img src="assets/images/icon/twitter-footer.svg" alt="X-Twitter">
									</a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1 col-footer">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Organization</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="">about us</a></li>
                                <li><a href="">contact us</a></li>
                                <li><a href="">our work</a></li>
								<li><a href="">contact us</a></li>
                                <li><a href="">feedback</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col col-footer">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Support</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="">term and service</a></li>
                                <li><a href="">shipping policy</a></li>
                                <li><a href="">return policy</a></li>
                                <li><a href="">refund policy</a></li>
                                <li><a href="">privacy policy</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col-md-3 col-footer">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>Stay Connected</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li>
									<!-- <i class="fa fa-map-marker"></i> -->
									<img src="assets/images/icon/location-footer.svg" alt="Address">
									2, Marjals Building, Mir Karam Ali Talpur Road
								</li>
								<li>
									<!-- <i class="fa fa-envelope-o"></i> -->
									<img src="assets/images/icon/envelope-footer.svg" alt="Email">
									<a>contact&#64;shanti.com</a>
								</li>
						        <li>
									<!-- <i class="fa fa-clock-o"></i> -->
									<img src="assets/images/icon/clock-footer.svg" alt="Time">
									Time: Mon-Fri 08:00 - 17:00
								</li>
						    </ul>
							<a class="btn btn-solid-footer" href="mailto::021312389870"><i class="fa fa-phone"></i>(021) 312 389 870</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer border-section">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p>
						<!-- <i class="fa fa-copyright" aria-hidden="true"></i>  -->
						Copyright {{ today | date:'y'}} All rights reserved.</p>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->