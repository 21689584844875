import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sides-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class SidesHeaderComponents implements OnInit {
  sidebarVisible: boolean = false;

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }
  constructor() { }

  ngOnInit(): void {
  }


}
