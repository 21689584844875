<app-header></app-header>

<!-- section start -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="account-sidebar" (click)="ToggleDashboard()">
                    <a hre="javascript:void(0)" class="popup-btn">
                        my account
                    </a>
                </div>
                <div class="dashboard-left" [class.open]="openDashboard">
                    <div class="collection-mobile-back" (click)="ToggleDashboard()">
                        <span class="filter-back">
                            <i class="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                    </div>
                    <div class="block-content">
                        <ul>
                            <li class="active"><a routerLink="/dashboard">Account Info</a></li>
                            <li><a routerLink="/profile">My Profile</a></li>
                            <li><a routerLink="/address">Address Book</a></li>
                            <li><a routerLink="/orders">My Orders</a></li>
                            <li><a hrrouterLinkef="/cancellation">My Cancellations</a></li>
                            <li><a routerLink="/change-password">Change Password</a></li>
                            <li class="last"><a routerLink="/login">Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-right">
                    <div class="dashboard">
                        <div class="page-title">
                            <h2>My Dashboard</h2>
                        </div>
                        <div class="welcome-msg">
                            <p>Hello, MARK JECNO !</p>
                            <p>From your My Account Dashboard you have the ability to view a snapshot of your recent account activity and update your account information. Select a link below to view or edit information.</p>
                        </div>
                        <div class="box-account box-info">
                            <div class="page-title box-head">
                                <h2>Account Information</h2>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="box">
                                        <div class="box-content">
                                            <h6>MARK JECNO</h6>
                                            <h6>MARk-JECNO&#64;gmail.com</h6>
                                            <h6>+910214454848</h6>
                                            <h6><a href="javascript:void(0)">Edit Info</a></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div>
                                <div class="box">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <h6 class="mb-3">Default Billing Address</h6>
                                            <address>
                                                <p>Mark Jecno</p>
                                                <p>2, Marjals Building, Mir Karam Ali Talpur Road</p>
                                                <p>(021) 312 389 870 </p>
                                                <a href="javascript:void(0)">Edit Address</a>
                                            </address>
                                        </div>
                                        <div class="col-sm-6">
                                            <h6 class="mb-3">Default Shipping Address</h6>
                                            <address>
                                                <p>Mark Jecno</p>
                                                <p>2, Marjals Building, Mir Karam Ali Talpur Road</p>
                                                <p>(021) 312 389 870 </p>
                                                <a href="javascript:void(0)">Edit Address</a>
                                            </address>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end -->

<app-footer></app-footer>