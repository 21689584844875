<!-- price filter start here -->
<div class="collection-collapse-block border-0" [class.open]="collapse">
    <h3 class="collapse-block-title" (click)="collapse = !collapse">price</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <div class="mt-2">
                <ngx-slider 
                    [(value)]="min" 
                    [(highValue)]="max" 
                    [options]="options" 
                    (userChangeEnd)="appliedFilter($event)"
                    *ngIf="isBrowser"
                    class="price-range-slider"
                >
                </ngx-slider>
            </div>
        </div>
    </div>
</div>