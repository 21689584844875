<!-- header start -->
<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
      <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="header-contact">
                <ul>
                  <li class="">
                    <a [routerLink]="">
                      <!-- <i class="fa fa-facebook" aria-hidden="true"></i> -->
                      <img src="assets/images/icon/fb-header.svg" class="top-social-icon-dark-theme" alt="Facebook">
                      <img src="assets/images/icon/fb-header-light.svg" class="top-social-icon-light-theme" alt="Facebook">
                    </a>
                  </li>
                  <li class="">
                    <a [routerLink]="">
                      <!-- <i class="fa fa-instagram" aria-hidden="true"></i> -->
                      <img src="assets/images/icon/insta-header.svg" class="top-social-icon-dark-theme" alt="Instagram">
                      <img src="assets/images/icon/insta-header-light.svg" class="top-social-icon-light-theme" alt="Instagram">
                    </a>
                  </li>
                  <li class="">
                    <a [routerLink]="">
                      <!-- <i class="fa fa-linkedin" aria-hidden="true"></i> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#d59a36" class="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                      </svg> -->
                      <img src="assets/images/icon/twitter-header.svg" class="top-social-icon-dark-theme" alt="X-Twitter">
                      <img src="assets/images/icon/twitter-header-light.svg" class="top-social-icon-light-theme" alt="X-Twitter">
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 text-end">
              <ul class="header-dropdown">
                <li class="web-theme">
                  <div class="theme-layout-version" (click)="customizeLayoutDark()">{{ themeText }}</div>
                </li>
                <li class="account-separator mobile-account">|</li>
                <li class="onhover-dropdown mobile-account">
                  
                  My Account
                  <ul class="onhover-show-div">
                    <li>
                      <a data-lng="en">
                        Login
                      </a>
                    </li>
                    <li>
                      <a data-lng="es">
                        Logout
                      </a>
                    </li>
                   
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
    </div>
    <div class="mid-header border-section">
      <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu border-top-0">
                  <div class="menu-left" style="color: #d59a36;">
                    <app-left-menu></app-left-menu>
                    <div class="menu-contact">
                      <!-- <svg style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-headset" viewBox="0 0 16 16">
                        <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5"/>
                      </svg> -->
                      <img src="assets/images/icon/headphone-top-banner.svg" class="headphone-dark-theme" alt="Contact">
                      <img src="assets/images/icon/headphone-light.svg" class="headphone-light-theme" alt="Contact">
                      <!-- <i class="fa fa-headphones" style="font-size: 30px; margin-right: 10px;" aria-hidden="true"></i> -->
                      Contact us: (021) 312 389 870
                    </div>
                  </div>
                  <div class="brand-logo layout2-logo headerlogo">
                      <a [routerLink]="['']">
                        <img [src]="themeLogo" class="img-fluid" alt="logo">
                      </a>
                  </div>
                  <div class="menu-right pull-right">
                    <app-settings></app-settings>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="bottom-header border-section border-top-0">
      <div class="container">
        <div class="row ">
            <div class="col-lg-12">
              <div class="main-nav-center">
                  <app-menu></app-menu>
              </div>
            </div>
        </div>
      </div>
    </div>
</header>
<!-- header end -->