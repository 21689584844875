import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-return-policy',
  templateUrl: './return-policy.component.html',
  styleUrls: ['./return-policy.component.scss']
})
export class ReturnPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#d59a36');
    document.documentElement.style.setProperty('--theme-gradient1', '#222222');
    document.documentElement.style.setProperty('--theme-gradient2', '#222222');
  }

}
