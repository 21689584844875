<app-header></app-header>

<!-- section start -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="account-sidebar" (click)="ToggleDashboard()">
                    <a hre="javascript:void(0)" class="popup-btn">
                        my account
                    </a>
                </div>
                <div class="dashboard-left" [class.open]="openDashboard">
                    <div class="collection-mobile-back" (click)="ToggleDashboard()">
                        <span class="filter-back">
                            <i class="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                    </div>
                    <div class="block-content">
                        <ul>
                            <li><a routerLink="/dashboard">Account Info</a></li>
                            <li><a routerLink="/profile">My Profile</a></li>
                            <li><a routerLink="/address">Address Book</a></li>
                            <li class="active"><a routerLink="/orders">My Orders</a></li>
                            <li><a routerLink="/cancellation">My Cancellations</a></li>
                            <li><a routerLink="/change-password">Change Password</a></li>
                            <li class="last"><a routerLink="/login">Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-right">
                    <div class="dashboard">
                        <div class="page-title">
                            <h2>My Orders</h2>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <table class="table table-color table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Order No.</th>
                                            <th scope="col">Placed On</th>
                                            <th scope="col">Payment Mode</th>
                                            <th scope="col">Bill Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of orders">
                                            <td>{{ item.order_no }}</td>
                                            <td>{{ item.placed_on }}</td>
                                            <td>{{ item.payment_mode }}</td>
                                            <td>{{ item.bill_amount | number }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination
                                        [collectionSize]="collectionSize"
                                        [(page)]="page"
                                        [pageSize]="pageSize"
                                        (pageChange)="refreshOrders()"
                                    >
                                    </ngb-pagination>
                                
                                    <select class="form-control" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshOrders()">
                                        <option [ngValue]="10">10 items per page</option>
                                        <option [ngValue]="25">25 items per page</option>
                                        <option [ngValue]="50">50 items per page</option>
                                        <option [ngValue]="100">100 items per page</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end -->

<app-footer></app-footer>