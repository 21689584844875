<div class="col-md-3">
    <button class="btn btn-primary d-md-none thline" type="button" (click)="toggleSidebar()"
        aria-label="Toggle navigation">
        <i class="fa fa-bars" aria-hidden="true"></i>
    </button>

    <div class="sidebar-container" [ngClass]="{'show': sidebarVisible}" id="sidebarMenu">
        <div class="sidebar-logo">
            My Account
        </div>
        <ul class="sidebar-navigation">
            <li class="header">Hello, Khawar Khaliq</li>
            <li>
                <a href="/dashboard" class="activelink">
                    <i class="fa fa-address-card" aria-hidden="true" ></i> Dasbaord
                </a>
            </li>
            <li>
                <a href="/profile" class="">
                    <i class="fa fa-user" aria-hidden="true"></i> My Profile
                </a>
            </li>

            <li>
                <a href="/orders" class="">
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i> My Orders
                </a>
            </li>
            <li>
                <a href="/cancellation" class="">
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i> My Cancellation
                </a>
            </li>
            <li>
                <a href="/wishlist" class="">
                    <i class="fa fa-heart" aria-hidden="true"></i> Wishlist
                </a>
            </li>
            <li>
                <a href="/address" class="">
                    <i class="fa fa-home" aria-hidden="true"></i> Address
                </a>
            </li>
            <li>
                <a href="/change-password" class="">
                    <i class="fa fa-key" aria-hidden="true"></i> Change Password
                </a>
            </li>
            <li>
                <a href="/logout">
                    <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
                </a>
            </li>
        </ul>
    </div>
</div>