<app-header></app-header>
<!-- section start -->

<section class="wishlist-section section-b-space">
    <div class="container">
        <div class="row">
            <!-- <app-sides-header class="sideb"></app-sides-header> -->

            <div class="col-md-12">
                
                <section class="contact-page register-page ptt">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12">
                                <h3>Edit Address</h3>
                                <form class="theme-form">
                                    <div class="row">
                                        <div class="col-md-6 select_input">
                                            <label for="review">Select a label for effective delivery: </label>
                                            <select class="form-control" size="1">
                                                <!-- <option selected disabled value="">Select Gender</option> -->
                                                <option>Home</option>
                                                <option>Office</option>
                                               
                                            </select>
                                        </div>
                                        <div class="col-md-6 select_input">
                                            <label for="review">Country: </label>
                                            <select class="form-control" size="1">
                                                <option selected disabled value="">Select Country</option>
                                                <option>Pakistan</option>
                                                
                                               
                                            </select>
                                        </div>
                                        <div class="col-md-6 mt-4">
                                            <label for="name">Full Name</label>
                                            <input type="text" class="form-control" id="name" placeholder="Enter Your name" required="">
                                        </div>
                                        <div class="col-md-6 select_input mt-4">
                                            <label for="review">State : </label>
                                            <select class="form-control" size="1">
                                                <option selected disabled value="">Select State</option>
                                                <option>Sindh</option>
                                                
                                               
                                            </select>
                                        </div>
                                    
                                        <div class="col-md-6">
                                            <label for="review">Phone number</label>
                                            <input type="text" class="form-control" id="review" placeholder="+92 00000000" required="">
                                        </div>
                                        <div class="col-md-6 select_input">
                                            <label for="review">City </label>
                                            <select class="form-control" size="1">
                                                <option selected  value="">Karachi</option>
                                                <option value="UAE">Kashmor</option>
                                                
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="name">Address :</label>
                                            <input type="text" class="form-control" id="name" placeholder="Flat No.000, Al xxxx xxxxx xxxx No.x" required="">
                                        </div>
                                       
                                        
                                        
                                        <!-- <div class="col-md-12">
                                            <label for="review">Write Your Message</label>
                                            <textarea class="form-control mb-0" placeholder="Write Your Message" id="exampleFormControlTextarea1" rows="6"></textarea>
                                        </div> -->
                                     
                                    </div>
                                </form>
                                <div class="row wishlist-buttons">
                                    <div class="col-12">
                                        <a [routerLink]="['/shop/collection/left/sidebar']" class="btn btn-solid">Update</a>
                                        <!-- <a [routerLink]="['/shop/checkout']" class="btn btn-solid">check out</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- Section ends -->
                <!-- address section start -->
                <!-- <section class="contact-page register-page section-b-space">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12">
                                <h3>SHIPPING ADDRESS</h3>
                                <form class="theme-form">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="name">flat / plot</label>
                                            <input type="text" class="form-control" id="home-ploat" placeholder="company name" required="">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="name">Address *</label>
                                            <input type="text" class="form-control" id="address-two" placeholder="Address" required="">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="email">Zip Code *</label>
                                            <input type="text" class="form-control" id="zip-code" placeholder="zip-code" required="">
                                        </div>
                                        <div class="col-md-6 select_input">
                                            <label for="review">Country *</label>
                                            <select class="form-control" size="1">
                                                <option value="India">India</option>
                                                <option value="UAE">UAE</option>
                                                <option value="U.K">U.K</option>
                                                <option value="US">US</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="review">City *</label>
                                            <input type="text" class="form-control" id="city" placeholder="City" required="">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="review">Region/State *</label>
                                            <input type="text" class="form-control" id="region-state" placeholder="Region/state" required="">
                                        </div>
                                        <div class="col-md-12">
                                            <button class="btn btn-sm btn-solid" type="submit">Save setting</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section> -->
          
            
            </div>
        </div>
        <!-- <div class="row wishlist-buttons">
            <div class="col-12">
                <a [routerLink]="['/shop/collection/left/sidebar']" class="btn btn-solid">continue shopping</a>
                <a [routerLink]="['/shop/checkout']" class="btn btn-solid">check out</a>
            </div>
        </div> -->
    </div>
</section>

<app-footer></app-footer>
<!--section end-->