import { Component, OnInit } from '@angular/core';
import { Order } from '../order.interface';

const ORDERS: Order[] = [
	{
		order_no: 'S-0001',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
	{
		order_no: 'S-0002',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
	{
		order_no: 'S-0003',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
	{
		order_no: 'S-0004',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
  {
		order_no: 'S-0005',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
	{
		order_no: 'S-0006',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
	{
		order_no: 'S-0007',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
	{
		order_no: 'S-0008',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
  {
		order_no: 'S-0009',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
	{
		order_no: 'S-0010',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
	{
		order_no: 'S-0011',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
	{
		order_no: 'S-0012',
		placed_on: '03-Mar-2024 02:35',
		payment_mode: 'Bank Transfer',
    bill_amount: 5900
	},
];

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})

export class OrderComponents implements OnInit {

  public openDashboard: boolean = false;

  page = 1;
	pageSize = 10;
	collectionSize = ORDERS.length;
	orders = ORDERS;

  constructor() {
    this.refreshOrders();
  }

  refreshOrders() {
		this.orders = ORDERS.map((order, i) => ({ id: i + 1, ...order })).slice(
			(this.page - 1) * this.pageSize,
			(this.page - 1) * this.pageSize + this.pageSize,
		);
	}

  ngOnInit(): void {
  }

  ToggleDashboard() {
    this.openDashboard = !this.openDashboard;
  }

}
