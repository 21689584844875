<app-header></app-header>

<!-- section start -->
<section class="section-b-space  blog-page">
    <div class="container">
        <div class="row">
            <!--Blog List start-->
            <div class="col-12">
                <div class="row blog-media">
                    <div class="col-xl-6">
                        <div class="blog-left">
                            <a [routerLink]="'/blog-detail'"><img src="assets/images/blog/1.jpg" class="img-fluid" alt=""/></a>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="blog-right">
                            <div>
                                <h6>25 January 2018</h6>
                                <a [routerLink]="'/blog-detail'"><h4>you how all this mistaken idea of denouncing pleasure and praising pain was born.</h4></a>
                                <ul class="post-social">
                                    <li>Posted By : Admin Admin </li>
                                    <li><i class="fa fa-heart"></i>  5 Hits  </li>
                                    <li><i class="fa fa-comments"></i> 10  Comment</li>
                                </ul>
                                <p>Consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row blog-media">
                    <div class="col-xl-6">
                        <div class="blog-left">
                            <a [routerLink]="'/blog-detail'"><img src="assets/images/blog/2.jpg" class="img-fluid" alt=""/></a>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="blog-right">
                            <div>
                                <h6>25 January 2018</h6>
                                <a [routerLink]="'/blog-detail'"><h4>you how all this mistaken idea of denouncing pleasure and praising pain was born.</h4></a>
                                <ul class="post-social">
                                    <li>Posted By : Admin Admin </li>
                                    <li><i class="fa fa-heart"></i>  5 Hits  </li>
                                    <li><i class="fa fa-comments"></i> 10  Comment</li>
                                </ul>
                                <p>Consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row blog-media">
                    <div class="col-xl-6">
                        <div class="blog-left">
                            <a [routerLink]="'/blog-detail'"><img src="assets/images/blog/3.jpg" class="img-fluid" alt=""/></a>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="blog-right">
                            <div>
                                <h6>25 January 2018</h6>
                                <a [routerLink]="'/blog-detail'"><h4>you how all this mistaken idea of denouncing pleasure and praising pain was born.</h4></a>
                                <ul class="post-social">
                                    <li>Posted By : Admin Admin </li>
                                    <li><i class="fa fa-heart"></i>  5 Hits  </li>
                                    <li><i class="fa fa-comments"></i> 10  Comment</li>
                                </ul>
                                <p>Consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row blog-media">
                    <div class="col-xl-6">
                        <div class="blog-left">
                            <a [routerLink]="'/blog-detail'"><img src="assets/images/blog/4.jpg" class="img-fluid" alt=""/></a>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="blog-right">
                            <div>
                                <h6>25 January 2018</h6>
                                <a [routerLink]="'/blog-detail'"><h4>you how all this mistaken idea of denouncing pleasure and praising pain was born.</h4></a>
                                <ul class="post-social">
                                    <li>Posted By : Admin Admin </li>
                                    <li><i class="fa fa-heart"></i>  5 Hits  </li>
                                    <li><i class="fa fa-comments"></i> 10  Comment</li>
                                </ul>
                                <p>Consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Blog List start-->
        </div>
    </div>
</section>
<!-- Section ends -->

<app-footer></app-footer>