import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: 'Home', type: 'link', active: true, path: '/home'
		},
		{
			title: 'About Us', type: 'link', active: false, path: '/aboutus'
		},
		{
			title: 'Categories', type: 'link', active: false, path: '/product'
		},
		{
			title: 'Products', type: 'link', active: false, path: '/product-detail'
		},
		{
			title: 'Blogs', type: 'link', active: false, path: '/blogs'
		},
		{
			title: 'Contact Us', type: 'link', active: false, path: '/contact'
		}
	];

	LEFTMENUITEMS: Menu[] = [
		{
			title: 'Home', type: 'link', active: true, path: '/home'
		},
		{
			title: 'About Us', type: 'link', active: false, path: '/aboutus'
		},
		{
			title: 'Categories', type: 'link', active: false, path: '/product'
		},
		{
			title: 'Products', type: 'link', active: false, path: '/product-detail'
		},
		{
			title: 'Blogs', type: 'link', active: false, path: '/blogs'
		},
		{
			title: 'Contact Us', type: 'link', active: false, path: '/contact'
		}
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);
}
