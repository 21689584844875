<app-header></app-header>

<!-- section start -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="account-sidebar" (click)="ToggleDashboard()">
                    <a hre="javascript:void(0)" class="popup-btn">
                        my account
                    </a>
                </div>
                <div class="dashboard-left" [class.open]="openDashboard">
                    <div class="collection-mobile-back" (click)="ToggleDashboard()">
                        <span class="filter-back">
                            <i class="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                    </div>
                    <div class="block-content">
                        <ul>
                            <li><a routerLink="/dashboard">Account Info</a></li>
                            <li><a routerLink="/profile">My Profile</a></li>
                            <li><a routerLink="/address">Address Book</a></li>
                            <li><a routerLink="/orders">My Orders</a></li>
                            <li><a routerLink="/cancellation">My Cancellations</a></li>
                            <li class="active"><a routerLink="/change-password">Change Password</a></li>
                            <li class="last"><a routerLink="/login">Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-right">
                    <div class="dashboard">
                        <div class="page-title">
                            <h2>Change Password</h2>
                        </div>
                        <form class="theme-form">
                            <div class="row">
                                <div class="col-md-4 mb-3">
                                    <label for="name">Current Password</label>
                                    <input type="text" class="form-control" id="name" placeholder="Enter Your Current Passowrd" required="">
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="email">New Password</label>
                                    <input type="text" class="form-control" id="last-name"  placeholder="Enter Your New Passowrd"   required="">
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="review">Confirm Passowrd</label>
                                    <input type="text" class="form-control" id="review" placeholder="Re-Enter Your New Passowrd"  required="">
                                </div>
                            </div>
                        </form>
                        <div class="row mb-5">
                            <div class="col-sm-3">
                                <a [routerLink]="['']" class="btn btn-solid">Update</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end -->

<app-footer></app-footer>