<app-header></app-header>

<!--section start-->
<section class=" contact-page section-b-space">
    <div class="container">
        <div class="row section-b-space">
            <div class="col-lg-7 map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.128277351857!2d67.02857207444565!3d24.859468045299053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e71b41c506b%3A0x9f6ef85029a8bb20!2sMir%20Karam%20Ali%20Talpur%20Rd%2C%20Saddar%20Karachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1707210850506!5m2!1sen!2s" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d50059.12775918716!2d72.78534673554945!3d21.16564923510817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1533793756956" allowfullscreen></iframe> -->
            </div>
            <div class="col-lg-5">
                <div class="contact-right">
                    <ul>
                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/headphone-footer-2.png" alt="Contact">
                                <h6>Contact Us</h6>
                            </div>
                            <div class="media-body">
                                <p>(021) 312 389 870</p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/location-footer.svg" alt="Address">
                                <h6>Address</h6>
                            </div>
                            <div class="media-body">
                                <p>2, Marjals Building, Mir Karam Ali, Talpur Road</p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/envelope-footer.svg" alt="Email">
                                <h6>Email</h6>
                            </div>
                            <div class="media-body">
                                <p>contact&#64;shanti.com</p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/clock-footer.svg" alt="Time">
                                <h6>Time</h6>
                            </div>
                            <div class="media-body">
                                <p>Mon-Fri 08:00 - 17:00</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <form class="theme-form">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="name">First Name</label>
                            <input type="text" class="form-control" id="name" placeholder="Enter Your name" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="email">Last Name</label>
                            <input type="text" class="form-control" id="last-name" placeholder="Email" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="review">Phone number</label>
                            <input type="text" class="form-control" id="review" placeholder="Enter your number" required="">
                        </div>
                        <div class="col-md-6">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" id="email" placeholder="Email" required="">
                        </div>
                        <div class="col-md-12">
                            <label for="review">Write Your Message</label>
                            <textarea class="form-control" placeholder="Write Your Message" id="exampleFormControlTextarea1" rows="6"></textarea>
                        </div>
                        <div class="col-md-12">
                            <button class="btn btn-solid" type="submit">Send Your Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->

<app-footer></app-footer>