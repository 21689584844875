import { Component, OnInit, Input, HostListener, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NavService, Menu } from '../../services/nav.service';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  @Input() class: string = 'header-2';
  @Input() themeLogo: string = 'assets/images/logos/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  public search: boolean = false;
  themeText: string = 'Light Theme';

  public menuItems: Menu[];
  public products: Product[] = [];

  constructor(
    private router: Router, 
    public navServices: NavService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public productService: ProductService
  ) {
      this.navServices.leftMenuItems.subscribe(menuItems => this.menuItems = menuItems );
      this.router.events.subscribe((event) => {
        this.navServices.mainMenuToggle = false;
      });
      this.productService.cartItems.subscribe(response => this.products = response);
  }

  ngOnInit(): void {
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  onHover(menuItem) {
    if(window.innerWidth > 1200 && menuItem){
       document.getElementById('unset').classList.add('sidebar-unset')
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset')
    }
  }

  searchToggle(){
    this.search = !this.search;
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  customizeLayoutDark() {
    // Toggle the text between "Light Theme" and "Dark Theme"
    this.themeText = this.themeText === 'Light Theme' ? 'Dark Theme' : 'Light Theme';
    document.body.classList.toggle('dark')
  }
}
