import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ProductComponent } from './pages/product/product.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TermsServicesComponent } from './pages/terms-services/terms-services.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
// import { DashboardComponents } from './myaccount/dashboard/dashboard.component';
// import { ProfileComponent } from './myaccount/profile/profile.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ReturnPolicyComponent } from './pages/return-policy/return-policy.component';
import { ShippingPolicyComponent } from './pages/shipping-policy/shipping-policy.component';
import { RefundPolicyComponent } from './pages/refund-policy/refund-policy.component';
import { WishlistComponents } from './myaccount/wishlist/wishlist.component';
// import { OrderComponents } from './myaccount/orders/order.component';
// import { CancellationComponents } from './myaccount/cancellation/cancellation.component';
// import { AddressComponents } from './myaccount/address/address.component';
// import { PasswordComponent } from './myaccount/change_password/password.component';
import { EditAddressComponent } from './myaccount/edit address/edit_address.component';

//Alnoor
import { DashboardComponent } from './account/dashboard/dashboard.component';
import { ProfileComponent } from './account/profile/profile.component';
import { AddressComponents } from './account/address/address.component';
import { PasswordComponent } from './account/change_password/password.component';
import { OrderComponents } from './account/orders/order.component';
import { CancellationComponents } from './account/cancellation/cancellation.component';
import { LoginComponent } from './account/login/login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  // {
  //   path: '',
  //   redirectTo: 'home/fashion',
  //   pathMatch: 'full'
  // },
  {
    path: 'home',
    component: HomeComponent
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  // },
  {
    path: 'aboutus', 
    component: AboutUsComponent
  },
  {
    path: 'address', 
    component: AddressComponents
  },
  {
    path: 'edit-address', 
    component: EditAddressComponent
  },
  {
    path: 'change-password', 
    component: PasswordComponent
  },
  {
    path: 'dashboard', 
    component: DashboardComponent
  },
  {
    path: 'orders', 
    component: OrderComponents
  },
  {
    path: 'cancellation', 
    component: CancellationComponents
  },
  {
    path: 'profile', 
    component: ProfileComponent
  },
  {
    path: 'wishlist', 
    component: WishlistComponents
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'product', 
    component: ProductComponent
  },
  {
    path: 'product-detail',
    component: ProductDetailComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'term-service',
    component: TermsServicesComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'return-policy',
    component: ReturnPolicyComponent
  },
  {
    path: 'shipping-policy',
    component: ShippingPolicyComponent
  },
  {
    path: 'refund-policy',
    component: RefundPolicyComponent
  },
  {
    path: 'blogs',
    component: BlogsComponent
  },
  {
    path: 'blog-detail',
    component: BlogDetailsComponent
  },
  {
    path: 'shop',
    component: ShopComponent
  },
  { 
    path: 'pages',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'elements', 
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
