<div class="img-wrapper">
    <div class="lable-block" *ngIf="product.new"><span class="lable3">new</span></div>
    <div class="front">
        <a [routerLink]="['/pages/home/', product.title.replace(' ', '-')]">
            <img  [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'"
                  [lazyLoad]="product.images[0].src" class="img-product"
                  alt="{{ product.images[0].alt }}">
        </a>
    </div>
    <!-- <div class="cart-box">
        <button title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
        	<i class="ti-shopping-cart"></i> 
        </button>
        <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
        	<i class="ti-shopping-cart"></i> 
        </button>
        <a href="javascript:void(0)" (click)="addToWishlist(product)" title="Add to Wishlist">
        	<i class="ti-heart" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" class="mobile-quick-view" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" (click)="addToCompare(product)" title="Compare">
        	<i class="ti-reload" aria-hidden="true"></i>
        </a>
    </div> -->
    <!-- <div class="quick-view-part">
        <a href="javascript:void(0)" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
    </div> -->
</div>
<div class="product-info">
    <a [routerLink]="['/pages/home/', product?.title.replace(' ', '-')]">
      <h6>{{ product?.title | titlecase }}</h6>
    </a>
    <h4 style="color: #d59a36;">
        5500 PKR
      <!-- {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }} -->
      <del><span class="money"> 7500 PKR</span></del>
    </h4>
    <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
</div>
<div class="add-to-cart">
    <a [routerLink]="['add-to-cart']">
        <!-- <i class="ti-shopping-cart" aria-hidden="true"></i> -->
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#d59a36" class="bi bi-cart" viewBox="0 0 16 16">
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
        </svg> -->
        <img src="assets/images/icon/product-cart.svg" alt="Shopping">
        <span>Add To Cart</span>
    </a>
</div>
<!-- <div class="product-info">
    <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
    <a [routerLink]="['/pages/home/', product?.title.replace(' ', '-')]">
    	<h6>{{ product?.title | titlecase }}</h6>
    </a>
    <h4>{{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}</h4>
</div> -->

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>