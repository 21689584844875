<app-header></app-header>

<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="product-left">
                        <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar class="product-slick">
                            <ng-container *ngFor="let item of carouselItems; index as i">
                                <ng-template carouselSlide [id]="i">
                                    <div class="product-image-left">
                                        <img class="main-image" 
                                            [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'" 
                                            lazyLoad="assets/images/product/vape/cinamon-candy-flv.png" 
                                            src="assets/images/product/vape/cinamon-candy-flv.png"
                                            [alt]=""
                                        >
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                        <div class="row">
                            <div class="col-12">
                                <div class="slider-nav">
                                    <owl-carousel-o [options]="ProductDetailsThumbConfig" class="product-slick">
                                        <ng-container *ngFor="let item of carouselItems; index as i">
                                            <ng-template carouselSlide [id]="i">
                                                <div class="owl-thumb" [class.active]="i == activeSlide">
                                                    <img class="thumb-image"
                                                        [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'" 
                                                        lazyLoad="assets/images/product/vape/cinamon-candy-flv.png" 
                                                        src="assets/images/product/vape/cinamon-candy-flv.png" 
                                                        [alt]="" 
                                                        (click)="owlCar.to(activeSlide = i.toString())"
                                                    >
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2>Vanila Matcha BY SHANTI <br>TFN SERIES 100ML</h2>
                        <p>
                            Air Factory Dutch Apple (Apple Pie) Tobacco Free Nicotine 100ML
                            e-liquid is a blend of mouth watering apples that are backed into
                            a pie crust with gooey caramel to delight your taste buds.
                        </p>
                        <p>
                            The best way to experience the true E Liquid it's paired best
                            with a Starter Kit or Sub-Ohm tanks.
                        </p>
                        <h3 style="color: #d59a36;">
                            <del><span class="money"> 7500 PKR</span></del>
                            5500 PKR
                        </h3>
                        <ul class="color-variant">
                            <li class="yellow active ng-star-inserted" style="background-color: yellow;"></li>
                            <li class="white ng-star-inserted" style="background-color: white;"></li>
                            <li class="pink ng-star-inserted" style="background-color: pink;"></li>
                        </ul>
                        <h6 class="product-title size-text">
                            Select Size
                        </h6>
                        <div class="size-box">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)">S</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">M</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">L</a>
                                </li>
                            </ul>
                        </div>
                        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                        <div class="product-description">
                            <h6 class="product-title">Quantity:</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                <span class="input-group-prepend">
                                    <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                                            <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                                        </svg>
                                    </button>
                                </span>
                                <input type="text" name="quantity" class="form-control input-number" [value]="counter" disabled/>
                                <span class="input-group-prepend">
                                    <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                                        </svg>
                                    </button>
                                </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-buttons">
                            <a href="javascrip:void(0)" class="btn btn-buy-now" [class.disabled]="" (click)="buyNow(product)">Buy Now</a>
                            <a href="javascrip:void(0)" class="btn btn-add-cart" [class.disabled]="" (click)="addToCart(product)">Add to Cart</a>
                        </div>
                    </div>
                </div>
            </div>
            <section class="tab-product m-0">
                <div class="row">
                    <div class="col-sm-12 col-lg-12">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>Description</a>
                            <ng-template ngbNavContent>
                                <p>
                                    It is a long established fact that a reader will be distracted by the readable content of a page when 
                                    looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution 
                                    of letters,It is a long established fact that a reader will be distracted by the readable content of a 
                                    page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal 
                                    distribution of letters.
                                </p>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink>Reviews</a>
                            <ng-template ngbNavContent>
                                <form class="theme-form">
                                <div class="row">
                                    <div class="col-md-12 ">
                                    <div class="media rating-sec">
                                        <label>Rating</label>
                                        <div class="media-body ms-3">
                                        <div class="rating three-star">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="name">Name</label>
                                        <input type="text" class="form-control" id="name" placeholder="Enter Your name" required>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="email">Email</label>
                                        <input type="text" class="form-control" id="email" placeholder="Email" required>
                                    </div>
                                    <div class="col-md-12">
                                        <label for="review">Review Title</label>
                                        <input type="text" class="form-control" id="review" placeholder="Enter your Review Subjects" required>
                                    </div>
                                    <div class="col-md-12">
                                        <label for="review">Review Title</label>
                                        <textarea class="form-control" placeholder="Wrire Your Testimonial Here" id="exampleFormControlTextarea1" rows="6"></textarea>
                                    </div>
                                    <div class="col-md-12">
                                        <button class="btn btn-solid" type="submit">Submit Your Review</button>
                                    </div>
                                </div>
                                </form>
                            </ng-template>
                        </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>

<section class="related-products">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h3>You May Also Like</h3>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="product-box">
                    <div class="img-wrapper">
                        <div class="lable-block">
                            <span class="lable3">new</span>
                        </div>
                        <div class="front">
                            <a [routerLink]="">
                                <img  [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'"
                                        [lazyLoad]="" class="img-product"
                                        alt="">
                            </a>
                        </div>
                    </div>
                    <div class="product-info">
                        <a [routerLink]="">
                            <h6>Menthol E-cigarette Kit</h6>
                        </a>
                        <h4 style="color: #d59a36;">
                            <del><span class="money"> 7500 PKR</span></del>
                            5500 PKR
                        </h4>
                        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                    </div>
                    <div class="add-to-cart">
                        <a [routerLink]="['add-to-cart']">
                            <img src="assets/images/icon/product-cart.svg" alt="Shopping">
                            <span>Add To Cart</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="product-box">
                    <div class="img-wrapper">
                        <div class="lable-block">
                            <span class="lable3">new</span>
                        </div>
                        <div class="front">
                            <a [routerLink]="">
                                <img  [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'"
                                        [lazyLoad]="" class="img-product"
                                        alt="">
                            </a>
                        </div>
                    </div>
                    <div class="product-info">
                        <a [routerLink]="">
                            <h6>Menthol E-cigarette Kit</h6>
                        </a>
                        <h4 style="color: #d59a36;">
                            <del><span class="money"> 7500 PKR</span></del>
                            5500 PKR
                        </h4>
                        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                    </div>
                    <div class="add-to-cart">
                        <a [routerLink]="['add-to-cart']">
                            <img src="assets/images/icon/product-cart.svg" alt="Shopping">
                            <span>Add To Cart</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="product-box">
                    <div class="img-wrapper">
                        <div class="lable-block">
                            <span class="lable3">new</span>
                        </div>
                        <div class="front">
                            <a [routerLink]="">
                                <img  [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'"
                                        [lazyLoad]="" class="img-product"
                                        alt="">
                            </a>
                        </div>
                    </div>
                    <div class="product-info">
                        <a [routerLink]="">
                            <h6>Menthol E-cigarette Kit</h6>
                        </a>
                        <h4 style="color: #d59a36;">
                            <del><span class="money"> 7500 PKR</span></del>
                            5500 PKR
                        </h4>
                        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                    </div>
                    <div class="add-to-cart">
                        <a [routerLink]="['add-to-cart']">
                            <img src="assets/images/icon/product-cart.svg" alt="Shopping">
                            <span>Add To Cart</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="product-box">
                    <div class="img-wrapper">
                        <div class="lable-block">
                            <span class="lable3">new</span>
                        </div>
                        <div class="front">
                            <a [routerLink]="">
                                <img  [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'"
                                        [lazyLoad]="" class="img-product"
                                        alt="">
                            </a>
                        </div>
                    </div>
                    <div class="product-info">
                        <a [routerLink]="">
                            <h6>Menthol E-cigarette Kit</h6>
                        </a>
                        <h4 style="color: #d59a36;">
                            <del><span class="money"> 7500 PKR</span></del>
                            5500 PKR
                        </h4>
                        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                    </div>
                    <div class="add-to-cart">
                        <a [routerLink]="['add-to-cart']">
                            <img src="assets/images/icon/product-cart.svg" alt="Shopping">
                            <span>Add To Cart</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="product-box">
                    <div class="img-wrapper">
                        <div class="lable-block">
                            <span class="lable3">new</span>
                        </div>
                        <div class="front">
                            <a [routerLink]="">
                                <img  [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'"
                                        [lazyLoad]="" class="img-product"
                                        alt="">
                            </a>
                        </div>
                    </div>
                    <div class="product-info">
                        <a [routerLink]="">
                            <h6>Menthol E-cigarette Kit</h6>
                        </a>
                        <h4 style="color: #d59a36;">
                            <del><span class="money"> 7500 PKR</span></del>
                            5500 PKR
                        </h4>
                        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                    </div>
                    <div class="add-to-cart">
                        <a [routerLink]="['add-to-cart']">
                            <img src="assets/images/icon/product-cart.svg" alt="Shopping">
                            <span>Add To Cart</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="product-box">
                    <div class="img-wrapper">
                        <div class="lable-block">
                            <span class="lable3">new</span>
                        </div>
                        <div class="front">
                            <a [routerLink]="">
                                <img  [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'"
                                        [lazyLoad]="" class="img-product"
                                        alt="">
                            </a>
                        </div>
                    </div>
                    <div class="product-info">
                        <a [routerLink]="">
                            <h6>Menthol E-cigarette Kit</h6>
                        </a>
                        <h4 style="color: #d59a36;">
                            <del><span class="money"> 7500 PKR</span></del>
                            5500 PKR
                        </h4>
                        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                    </div>
                    <div class="add-to-cart">
                        <a [routerLink]="['add-to-cart']">
                            <img src="assets/images/icon/product-cart.svg" alt="Shopping">
                            <span>Add To Cart</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="product-box">
                    <div class="img-wrapper">
                        <div class="lable-block">
                            <span class="lable3">new</span>
                        </div>
                        <div class="front">
                            <a [routerLink]="">
                                <img  [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'"
                                        [lazyLoad]="" class="img-product"
                                        alt="">
                            </a>
                        </div>
                    </div>
                    <div class="product-info">
                        <a [routerLink]="">
                            <h6>Menthol E-cigarette Kit</h6>
                        </a>
                        <h4 style="color: #d59a36;">
                            <del><span class="money"> 7500 PKR</span></del>
                            5500 PKR
                        </h4>
                        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                    </div>
                    <div class="add-to-cart">
                        <a [routerLink]="['add-to-cart']">
                            <img src="assets/images/icon/product-cart.svg" alt="Shopping">
                            <span>Add To Cart</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="product-box">
                    <div class="img-wrapper">
                        <div class="lable-block">
                            <span class="lable3">new</span>
                        </div>
                        <div class="front">
                            <a [routerLink]="">
                                <img  [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'"
                                        [lazyLoad]="" class="img-product"
                                        alt="">
                            </a>
                        </div>
                    </div>
                    <div class="product-info">
                        <a [routerLink]="">
                            <h6>Menthol E-cigarette Kit</h6>
                        </a>
                        <h4 style="color: #d59a36;">
                            <del><span class="money"> 7500 PKR</span></del>
                            5500 PKR
                        </h4>
                        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                    </div>
                    <div class="add-to-cart">
                        <a [routerLink]="['add-to-cart']">
                            <img src="assets/images/icon/product-cart.svg" alt="Shopping">
                            <span>Add To Cart</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>