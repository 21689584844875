<app-header></app-header>

<!-- section start -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="account-sidebar" (click)="ToggleDashboard()">
                    <a hre="javascript:void(0)" class="popup-btn">
                        my account
                    </a>
                </div>
                <div class="dashboard-left" [class.open]="openDashboard">
                    <div class="collection-mobile-back" (click)="ToggleDashboard()">
                        <span class="filter-back">
                            <i class="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                    </div>
                    <div class="block-content">
                        <ul>
                            <li><a routerLink="/dashboard">Account Info</a></li>
                            <li class="active"><a routerLink="/profile">My Profile</a></li>
                            <li><a routerLink="/address">Address Book</a></li>
                            <li><a routerLink="/orders">My Orders</a></li>
                            <li><a routerLink="/cancellation">My Cancellations</a></li>
                            <li><a routerLink="/change-password">Change Password</a></li>
                            <li class="last"><a routerLink="/login">Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-right">
                    <div class="dashboard">
                        <div class="page-title">
                            <h2>Personal Detail</h2>
                        </div>
                        <form class="theme-form">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label for="name">Full Name</label>
                                    <input type="text" class="form-control" id="name" placeholder="Enter Your name" required="">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label for="email">Email</label>
                                    <input type="text" class="form-control" id="last-name" value="" disabled placeholder="Email" required="">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label for="review">Phone Number</label>
                                    <input type="text" class="form-control" id="review" placeholder="Enter your number" value="" disabled required="">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 select_input">
                                    <label for="review">Gender</label>
                                    <select class="form-control" size="1">
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label for="email">DOB</label>
                                    <input type="date" class="form-control">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label for="email">Signup Date</label>
                                    <input type="text" class="form-control" disabled value="07-Dec-2022 11:35" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-12 mb-3 select_input">
                                    <label for="review">Country</label>
                                    <select class="form-control" size="1">
                                        <option selected disabled value="">Select Country</option>
                                        <option value="">Pakistan</option>
                                    </select>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12 mb-3 select_input">
                                    <label for="review">State</label>
                                    <select class="form-control" size="1">
                                        <option selected disabled value="">Select State</option>
                                        <option value="UAE">Sindh</option>
                                    </select>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12 mb-3 select_input">
                                    <label for="review">City</label>
                                    <select class="form-control" size="1">
                                        <option selected disabled value="">Select City</option>
                                        <option value="UAE">Karachi</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-12 mb-3">
                                    <label for="formFileSm">Profile Image</label>
                                    <input type="file" class="form-control mb-3" style="height: auto;" id="formFileSm" (change)="updateImage($event)">
                                    <br>
                                    <img src="{{ imageUrl }}" style="width: 100px; height: 100px;" alt="">
                                </div>
                            </div>
                            <div class="row mb-5">
                                <div class="col-sm-3">
                                    <a [routerLink]="['']" class="btn btn-solid">Update</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end -->

<app-footer></app-footer>