<div class="img-wrapper">
  <div class="lable-block" *ngIf="product.new"><span class="lable3">new</span></div>
  <div class="front">
    <a [routerLink]="">
      <img [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'" 
        [lazyLoad]="product.images[0].src" class="img-product" alt="{{ product.images[0].alt }}">
    </a>
  </div>
</div>
<div class="product-info">
  <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
    <h6>{{ product?.title | titlecase }}</h6>
  </a>
  <h4>
    {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
    <del><span class="money"> 7500 PKR</span></del>
  </h4>
  <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
</div>