<app-header></app-header>

<!-- section start -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="account-sidebar" (click)="ToggleDashboard()">
                    <a hre="javascript:void(0)" class="popup-btn">
                        my account
                    </a>
                </div>
                <div class="dashboard-left" [class.open]="openDashboard">
                    <div class="collection-mobile-back" (click)="ToggleDashboard()">
                        <span class="filter-back">
                            <i class="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                    </div>
                    <div class="block-content">
                        <ul>
                            <li><a routerLink="/dashboard">Account Info</a></li>
                            <li><a routerLink="/profile">My Profile</a></li>
                            <li class="active"><a routerLink="/address">Address Book</a></li>
                            <li><a routerLink="/orders">My Orders</a></li>
                            <li><a routerLink="/cancellation">My Cancellations</a></li>
                            <li><a routerLink="/change-password">Change Password</a></li>
                            <li class="last"><a routerLink="/login">Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-right">
                    <div class="dashboard">
                        <div class="row top-heading-row">
                            <div class="col-sm-4">
                                <div class="page-title">
                                    <h2>My Addresses</h2>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0)">Add New Address</a>
                                    </li>
                                    <li class="separator">|</li>
                                    <li>
                                        <a href="javascript:void(0)">Mark Default Shipping</a>
                                    </li>
                                    <li class="separator">|</li>
                                    <li>
                                        <a href="javascript:void(0)">Mark Default Billing</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row detail-cannabis">
                            <div class="col-md-6">
                                <div class="detail_section">
                                    <div>
                                        <div class="heading">
                                            <h4>Address Book</h4>
                                            <div class="radio-option">
                                                <input type="radio" name="payment-group" id="payment-1">
                                                <label for="payment-1">Default</label>
                                            </div>
                                        </div>
                                        <p><b>Mark Jecno</b></p>
                                        <p>2, Marjals Building, Mir Karam Ali Talpur Road</p>
                                        <p>(021) 312 389 870</p>
                                        <a [routerLink]="['']" class="btn btn-solid btn-solid-edit">Edit</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="detail_section">
                                    <div>
                                        <div class="heading">
                                            <h4>Address Book</h4>
                                            <div class="radio-option">
                                                <input type="radio" name="payment-group" id="payment-1">
                                                <label for="payment-1">Default</label>
                                            </div>
                                        </div>
                                        <p><b>Mark Jecno</b></p>
                                        <p>2, Marjals Building, Mir Karam Ali Talpur Road</p>
                                        <p>(021) 312 389 870</p>
                                        <a [routerLink]="['']" class="btn btn-solid btn-solid-edit">Edit</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end -->

<app-footer></app-footer>