<app-header></app-header>

<!-- Home banner start-->
<section class="p-0">
  <div class="full-banner p-left" [ngStyle]="{'background-image': 'url(assets/images/banner/home-banner.jpg)'}">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="banner-contain">
            <h4>Stop Smoke Lets Vaping<br> Puff with Shanti Vapes!</h4>
            <h5>Embrace serenity with Shanti Vapes Elevate your vaping<br> journey with our premium flavors and exceptional quality.</h5>
            <button type="submit" class="btn btn-solid">Shop Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Home banner End -->

<!-- home categories start -->
<section class="p-0 border-section category-section">
  <div class="title1 category-heading">
    <h2 class="title-inner1">Our Top Categories</h2>
  </div>
  <div class="container banner-slider">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12">
          <a [routerLink]="['/pages/home/']">
            <!-- <div class="box-lg" style="border: 1px solid #dddddd;">
              <img src="assets/images/categories/tranquality.png" class="box-image" alt="vape flavours">
            </div> -->
            <img src="assets/images/categories/flavours.png" class="img-fluid img-category img-web" alt="vape flavours">
            <img src="assets/images/categories/vape_mobile.png" class="img-fluid img-category img-mobile" alt="vape flavours">
          </a>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12">
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12">
            <a [routerLink]="['/pages/home/']">
              <!-- <div class="box-md" style="border: 1px solid #dddddd;">
                <img src="assets/images/categories/watch.png" class="box-image" alt="watches">
              </div> -->
              <img src="assets/images/categories/watch.png" class="img-fluid img-category img-web" alt="watches">
              <img src="assets/images/categories/watch_mobile.png" class="img-fluid img-category img-mobile" alt="watches">
            </a>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-7">
            <a [routerLink]="['/pages/home/']">
              <!-- <div class="box-md" style="border: 1px solid #dddddd;">
                <img src="assets/images/categories/shoe.png" class="box-image" alt="shoes">
              </div> -->
              <img src="assets/images/categories/shoe.png" class="img-fluid img-category" alt="shoes">
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 col-md-7 col-sm-7">
            <a [routerLink]="['/pages/home/']">
              <!-- <div class="box-md" style="border: 1px solid #dddddd;">
                <img src="assets/images/categories/shoe.png" class="box-image" alt="vapes">
              </div> -->
              <img src="assets/images/categories/vape.png" class="img-fluid img-category" alt="vapes">
            </a>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-12">
            <a [routerLink]="['/pages/home/']">
              <!-- <div class="box-md" style="border: 1px solid #dddddd;">
                <img src="assets/images/categories/t-shirt.png" class="box-image" alt="clothing">
              </div> -->
              <img src="assets/images/categories/cloth.png" class="img-fluid img-category img-web" alt="clothing">
              <img src="assets/images/categories/clothing_mobile.png" class="img-fluid img-category img-mobile" alt="clothing">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- home categories end -->

<!-- Tab product start-->
<section class="p-0 product-tabs-section">
  <div class="proHeader">
    <div class="container">
      <div class="row">
        <div class="col-md-4 theme-heading">
          <div class="">
            <h3>Latest Arrival Products</h3>
          </div>
        </div>
        <div class="col theme-tab">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="tabs tab-title justify-content-center nav nav-pills">
            <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
              <a ngbNavLink>
                 {{ collection }}
              </a>
              <ng-template ngbNavContent>
                <div class="no-slider row">
                    <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                      <div class="product-box">
                          <div class="img-wrapper">
                            <div class="lable-block" *ngIf="product.new">
                              <span class="lable3">new</span>
                            </div>
                            <div class="front">
                                <a [routerLink]="['/pages/home/', product.title.replace(' ', '-')]">
                                    <img  [defaultImage]="'assets/images/product/vape/cinamon-candy-flv.png'"
                                          [lazyLoad]="product.images[0].src" class="img-product"
                                          alt="{{ product.images[0].alt }}">
                                </a>
                            </div>
                        </div>
                        <div class="product-info">
                            <a [routerLink]="['/pages/home/', product?.title.replace(' ', '-')]">
                              <h6>{{ product?.title | titlecase }}</h6>
                            </a>
                            <h4 style="color: #d59a36;">
                              <del><span class="money"> 7500 PKR</span></del>
                              5500 PKR
                            </h4>
                            <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
                        </div>
                        <div class="add-to-cart">
                            <a [routerLink]="['add-to-cart']">
                                <img src="assets/images/icon/product-cart.svg" alt="Shopping">
                                <span>Add To Cart</span>
                            </a>
                        </div>
                        <!-- <app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
                        <app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal> -->
                      </div>
                    </ng-container>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</section>
<!-- Tab product End -->

<section class="p-0 full-banner p-left section-middle-banner" [ngStyle]="{'background-image': 'url(assets/images/banner/middle_banner.png)'}">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 custom-flex-container">
        <div class="row">
          <div class="col">
            <img src="assets/images/banner/middle-image-01.png" class="img-fluid" alt="">
          </div>
          <div class="col custom-left-align">
            <img src="assets/images/banner/middle-image-02.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
        <div class="banner-contain middle-banner-content">
          <h4>Discover Transquility in Every<br> Puff with Shanti Vapes!</h4>
          <h5>Embrace serenity with Shanti Vapes Elevate your vaping<br> journey with our premium flavors and exceptional quality.</h5>
          <button type="submit" class="btn btn-solid">Shop Now</button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="offer-section">
  <div class="container">
    <div class="row home-banner-2">
      <div class="col-md-6">
        <h6>Get <span style="color: #d59a36;">25%</span> off now</h6>
        <h3>Unwind with Shanti</h3>
        <p>
          Where Flavor Meets Serenity, Dive into a world of tranquility with our exquisite vape blends. 
          Elevate your experience and savor the calm with each inhalation.
        </p>
        <p>Discover the essence of relaxation with Shanti Vapes.</p>
        <p style="color: #d59a36;">Coupon Code: Shanti25</p>
        <hr>
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-12">
            <h4>Hungr up!</h4>
            <p>Offer end in:</p>
          </div>
          <div class="col-lg-9 col-md-9 col-sm-12">
            <div class="timer-box">
              <div class="timer">
                <div class="timer-item">
                  <span>03</span>
                  <span class='timer-cal'>Days</span>
                </div>
                <div class="timer-item">
                  <span>16</span>
                  <span class='timer-cal'>Hr</span>
                </div>
                <div class="timer-item">
                  <span>46</span>
                  <span class='timer-cal'>Min</span>
                </div>
                <div class="timer-item">
                  <span>07</span>
                  <span class='timer-cal'>Sec</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <img src="assets/images/Unwind-with-shanti.png" alt="Unwind Shanti">
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>