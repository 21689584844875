import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { NgxSliderModule } from 'ngx-slider-v2';
import { WishlistComponents } from './myaccount/wishlist/wishlist.component';
import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';

// Pages
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ProductComponent } from './pages/product/product.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TermsServicesComponent } from './pages/terms-services/terms-services.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ReturnPolicyComponent } from './pages/return-policy/return-policy.component';
import { ShippingPolicyComponent } from './pages/shipping-policy/shipping-policy.component';
import { RefundPolicyComponent } from './pages/refund-policy/refund-policy.component';
// import { OrderComponents } from './myaccount/orders/order.component';

// Components
import { HeaderComponent } from './components/header/header.component';
import { SidesHeaderComponents } from './myaccount/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { PriceComponent } from './components/price/price.component';
import { StockInventoryComponent } from './components/stock-inventory/stock-inventory.component';
import { SocialComponent } from './components/social/social.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { RelatedProductComponent } from './components/related-product/related-product.component';
// import { DashboardComponents } from './myaccount/dashboard/dashboard.component';
// import { ProfileComponent } from './myaccount/profile/profile.component';
import { DiscountPipe } from './pipes/discount.pipe';
// import { CancellationComponents } from './myaccount/cancellation/cancellation.component';
// import { AddressComponents } from './myaccount/address/address.component';
// import { PasswordComponent } from './myaccount/change_password/password.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EditAddressComponent } from './myaccount/edit address/edit_address.component';

//Alnoor
import { DashboardComponent } from './account/dashboard/dashboard.component';
import { ProfileComponent } from './account/profile/profile.component';
import { AddressComponents } from './account/address/address.component';
import { PasswordComponent } from './account/change_password/password.component';
import { OrderComponents } from './account/orders/order.component';
import { CancellationComponents } from './account/cancellation/cancellation.component';
import { LoginComponent } from './account/login/login.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent,
    ElementsComponent,
    HeaderComponent,
    FooterComponent,
    CategoriesComponent,
    PriceComponent,
    StockInventoryComponent,
    CancellationComponents,
    SidesHeaderComponents,
    AddressComponents,
    PasswordComponent,
    SocialComponent,
    CountdownComponent,
    RelatedProductComponent,
    EditAddressComponent,
    OrderComponents,
    HomeComponent,
    AboutUsComponent,
    ProductComponent,
    ProfileComponent,
    ProductDetailComponent,
    ContactComponent,
    TermsServicesComponent,
    BlogsComponent,
    BlogDetailsComponent,
    PrivacyPolicyComponent,
    ReturnPolicyComponent,
    ShippingPolicyComponent,
    RefundPolicyComponent,
    DiscountPipe,
    WishlistComponents,
    DashboardComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    NgbPaginationModule,
    MatDatepickerModule,
    NgxSliderModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    SharedModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
