<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="header-contact">
                        <ul>
                            <li class="">
                                <a [routerLink]="">
                                    <img src="assets/images/icon/fb-header.svg" class="top-social-icon-dark-theme" alt="Facebook">
                                    <img src="assets/images/icon/fb-header-light.svg" class="top-social-icon-light-theme" alt="Facebook">
                                </a>
                            </li>
                            <li class="">
                                <a [routerLink]="">
                                    <img src="assets/images/icon/insta-header.svg" class="top-social-icon-dark-theme" alt="Instagram">
                                    <img src="assets/images/icon/insta-header-light.svg" class="top-social-icon-light-theme" alt="Instagram">
                                </a>
                            </li>
                            <li class="">
                                <a [routerLink]="">
                                    <img src="assets/images/icon/twitter-header.svg" class="top-social-icon-dark-theme" alt="X-Twitter">
                                    <img src="assets/images/icon/twitter-header-light.svg" class="top-social-icon-light-theme" alt="X-Twitter">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 text-end">
                    <ul class="header-dropdown">
                        <li class="web-theme">
                            <div class="theme-layout-version" (click)="customizeLayoutDark()">{{ themeText }}</div>
                        </li>
                        <li class="account-separator mobile-account">|</li>
                        <li class="onhover-dropdown mobile-account">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            My Account
                            <ul class="onhover-show-div">
                                <li>
                                    <a data-lng="en" routerLink="/dashboard">Account Setting</a>
                                </li>
                                <li>
                                    <a data-lng="en" routerLink="/login">Logout</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="mid-header border-section">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="main-menu border-top-0">
                        <div class="menu-left" style="color: #d59a36;">
                            <div class="navbar mobile-menu">
                                <a href="javascript:void(0)" (click)="leftMenuToggle()">
                                    <div class="bar-style">
                                        <i class="fa fa-bars sidebar-bar" aria-hidden="true"></i>
                                    </div>
                                </a>
                                <div id="mySidenav" class="sidenav" [class.openSide]="navServices?.leftMenuToggle">
                                    <a href="javascript:void(0)" class="sidebar-overlay" (click)="leftMenuToggle()" (mouseover)="onHover(false)"></a>
                                    <nav id="unset">
                                        <div (click)="leftMenuToggle()">
                                            <div class="sidebar-back text-start">
                                                <i class="fa fa-angle-left pe-2" aria-hidden="true"></i> Back
                                            </div>
                                        </div>
                                        <ul id="sub-menu" class="sidebar-menu">
                                            <li *ngFor="let menuItem of menuItems" (mouseover)="onHover(menuItem.children ? true : false)">
                                                <!-- Sub -->
                                                <a href="javascript:void(0)" *ngIf="menuItem.type === 'sub'" (click)="toggletNavActive(menuItem)">
                                                    {{menuItem.title}}
                                                    <span class="sub-arrow" *ngIf="menuItem.children"></span>
                                                </a>
                                                <!-- Link -->
                                                <a [routerLink]="menuItem.path" *ngIf="menuItem.type === 'link'">
                                                    {{menuItem.title}}
                                                    <span class="sub-arrow" *ngIf="menuItem.children"></span>
                                                </a>
                                                <!-- External Link -->
                                                <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extLink'">
                                                    {{menuItem.title}}
                                                    <span class="sub-arrow" *ngIf="menuItem.children"></span>
                                                </a>
                                                <!-- External Tab Link -->
                                                <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extTabLink'">
                                                    {{menuItem.title}}
                                                    <span class="sub-arrow" *ngIf="menuItem.children"></span>
                                                </a>
                                                <!-- 2nd Level Menu -->
                                                <ul [class]="menuItem.megaMenu ? 'mega-menu clothing-menu' : ''" [class.opensub1]="menuItem.active" *ngIf="menuItem.children">
                                                    <!-- Simple Menu Start-->
                                                    <ng-container *ngIf="!menuItem.megaMenu">
                                                        <li *ngFor="let childrenItem of menuItem.children">
                                                            <!-- Link -->
                                                            <a class="has-submenu" [routerLink]="childrenItem.path" *ngIf="childrenItem.type === 'link'">
                                                                {{childrenItem.title}}
                                                            </a>
                                                            <!-- External Link -->
                                                            <a href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink'">
                                                                {{childrenItem.title}}
                                                            </a>
                                                            <!-- External Tab Link -->
                                                            <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank" *ngIf="childrenItem.type === 'extTabLink'">
                                                                {{childrenItem.title}}
                                                            </a>
                                                            <!-- 3rd Level Menu -->
                                                            <ul *ngIf="childrenItem.children">
                                                                <li *ngFor="let childrenSubItem of childrenItem.children">
                                                                    <!-- Link -->
                                                                    <a [routerLink]="childrenSubItem.path" *ngIf="childrenSubItem.type === 'link'">
                                                                        {{childrenSubItem.title}}
                                                                    </a>
                                                                    <!-- External Link -->
                                                                    <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink'">
                                                                        {{childrenSubItem.title}}
                                                                    </a>
                                                                    <!-- External Tab Link -->
                                                                    <a href="{{ childrenSubItem.path }}" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink'">
                                                                        {{childrenSubItem.title}}
                                                                    </a>
                                                                    <!-- 4th Level Menu -->
                                                                    <ul *ngIf="childrenSubItem.children">
                                                                        <li *ngFor="let childrenSubSubItem of childrenSubItem.children">
                                                                            <!-- Link -->
                                                                            <a [routerLink]="childrenSubSubItem.path" *ngIf="childrenSubSubItem.type === 'link'">
                                                                                {{childrenSubSubItem.title}}
                                                                            </a>
                                                                            <!-- External Link -->
                                                                            <a href="{{ childrenSubSubItem.path }}" *ngIf="childrenSubSubItem.type === 'extLink'">
                                                                                {{childrenSubSubItem.title}}
                                                                            </a>
                                                                            <!-- External Tab Link -->
                                                                            <a href="{{ childrenSubSubItem.path }}" target="_blank" *ngIf="childrenSubSubItem.type === 'extTabLink'">
                                                                                {{childrenSubSubItem.title}}
                                                                            </a>
                                                                            <!-- 5th Level Menu -->
                                                                            <ul *ngIf="childrenSubSubItem.children">
                                                                                <li *ngFor="let childrenSubSubSubItem of childrenSubSubItem.children">
                                                                                    <!-- Link -->
                                                                                    <a [routerLink]="childrenSubSubSubItem.path" *ngIf="childrenSubSubSubItem.type === 'link'">
                                                                                        {{childrenSubSubSubItem.title}}
                                                                                    </a>
                                                                                    <!-- External Link -->
                                                                                    <a href="{{ childrenSubSubSubItem.path }}" *ngIf="childrenSubSubSubItem.type === 'extLink'">
                                                                                        {{childrenSubSubSubItem.title}}
                                                                                    </a>
                                                                                    <!-- External Tab Link -->
                                                                                    <a href="{{ childrenSubSubSubItem.path }}" target="_blank" *ngIf="childrenSubSubSubItem.type === 'extTabLink'">
                                                                                        {{childrenSubSubSubItem.title}}
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ng-container>
                                                    <!-- Simple Menu End-->
                                                    <!-- Mega Menu Start-->
                                                    <ng-container *ngIf="menuItem.megaMenu">
                                                        <li>
                                                            <div class="row m-0">
                                                                <div class="col-xl-4" *ngFor="let childrenItem of menuItem.children">
                                                                    <div class="link-section">
                                                                        <h5>{{childrenItem.title}}</h5>
                                                                        <ul>
                                                                            <li *ngFor="let childrenSubItem of childrenItem.children">
                                                                                <!-- Link -->
                                                                                <a [routerLink]="childrenSubItem.path" *ngIf="childrenSubItem.type === 'link'">
                                                                                    {{childrenSubItem.title}}
                                                                                </a>
                                                                                <!-- External Link -->
                                                                                <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink'">
                                                                                    {{childrenSubItem.title}}
                                                                                </a>
                                                                                <!-- External Tab Link -->
                                                                                <a href="{{ childrenSubItem.path }}" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink'">
                                                                                    {{childrenSubItem.title}}
                                                                                </a>
                                                                            </li>
                                                                        </ul>    
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-4">
                                                                    <a [routerLink]="['/home/one']" class="mega-menu-banner"><img src="" alt="" class="img-fluid"></a>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ng-container>
                                                    <!-- Mega Menu End-->
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div class="menu-contact">
                                <img src="assets/images/icon/headphone-top-banner.svg" class="headphone-dark-theme" alt="Contact">
                                <img src="assets/images/icon/headphone-light.svg" class="headphone-light-theme" alt="Contact">
                                Contact us: (021) 312 389 870
                            </div>
                        </div>
                        <div class="brand-logo layout2-logo headerlogo">
                            <a [routerLink]="['']">
                                <img [src]="themeLogo" class="img-fluid" alt="logo">
                            </a>
                        </div>
                        <div class="menu-right pull-right">
                            <div class="icon-nav">
                                <ul>
                                    <li class="mobile-theme">
                                        <div class="theme-layout-version" (click)="customizeLayoutDark()">{{ themeText }}</div>
                                    </li>
                                    <li class="mobile-search search-widgets" id="search-widgets">
                                        <div (click)="searchToggle()">
                                            <img src="assets/images/icon/search.png" class="img-fluid" alt="">
                                            <i class="ti-search"></i>
                                        </div>
                                        <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
                                            <div> 
                                                <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
                                                <div class="overlay-content">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-xl-12">
                                                                <form>
                                                                    <div class="form-group">
                                                                        <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Search a Product">
                                                                    </div>
                                                                    <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="search-container">
                                            <input type="text" class="form-control" id="search_bar" placeholder="Search...">
                                            <div class="search-icon">
                                                <i class="ti-search"></i>
                                            </div>
                                        </div>            
                                    </li>
                                    <li class="web-cart">
                                        <span class="cart_qty_cls">{{ products.length }}</span>
                                        <div class="cart_area">
                                            <ul>
                                                <li>
                                                    <img src="assets/images/icon/top-cart-white.svg" class="cart-white" alt="Shopping">
                                                    <img src="assets/images/icon/top-cart-black.svg" class="cart-black" alt="Shopping">
                                                </li>
                                                <li class="cart-separator">|</li>
                                                <li>0.00 PKR</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="onhover-div mobile-cart">
                                        <div>
                                            <a [routerLink]="['']">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
                                                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                                                </svg>
                                            </a>
                                        </div>
                                        <span class="cart_qty_cls">{{ products.length }}</span>
                                        <ul class="show-div shopping-cart" *ngIf='!products.length'>
                                            <h5>Your cart is currently empty.</h5>
                                        </ul>
                                        <ul class="show-div shopping-cart" *ngIf='products.length'>
                                            <li *ngFor="let product of products">
                                                <div class="media">
                                                    <a [routerLink]="['', product.title.replace(' ', '-')]">
                                                        <img class="me-3" [src]="product?.images[0].src" [alt]="product?.images[0].alt">
                                                    </a>
                                                    <div class="media-body">
                                                        <a [routerLink]="['', product.title.replace(' ', '-')]">
                                                            <h4>{{ product?.title }}</h4>
                                                        </a>
                                                        <h4>
                                                            <span>
                                                                {{ product?.quantity }} x 
                                                                {{ product?.price * productService?.Currency.price | discount:product | currency:productService?.Currency.currency:'symbol' }}
                                                            </span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div class="close-circle" (click)="removeItem(product)">
                                                    <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="total">
                                                    <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="buttons">
                                                    <a [routerLink]="['']" class="view-cart">view cart</a>
                                                    <a [routerLink]="['']" class="checkout">checkout</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-header border-section border-top-0">
        <div class="container">
            <div class="row ">
                <div class="col-lg-12">
                    <div class="main-nav-center">
                        <div class="main-navbar">
                            <div id="mainnav">
                                <div class="toggle-nav" (click)="mainMenuToggle()">
                                    <i class="fa fa-bars sidebar-bar"></i>
                                </div>
                                <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">
                                    <li class="back-btn">
                                        <div class="mobile-back text-end" (click)="mainMenuToggle()">
                                            <span>Back</span>
                                            <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                                        </div>
                                    </li>
                                    <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">
                                        <!-- Sub -->
                                        <a href="javascript:void(0)" class="nav-link" *ngIf="menuItem.type === 'sub'" (click)="toggletNavActive(menuItem)">
                                            {{ menuItem.title | translate }}
                                            <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
                                            <span class="sub-arrow" *ngIf="menuItem.children"></span>
                                        </a>
                                        <!-- Link -->
                                        <a [routerLink]="menuItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                            *ngIf="menuItem.type === 'link'">
                                            {{ menuItem.title }}
                                            <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
                                            <span class="sub-arrow" *ngIf="menuItem.children"></span>
                                        </a>
                                        <!-- External Link -->
                                        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extLink'">
                                            {{ menuItem.title }}
                                            <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
                                            <span class="sub-arrow" *ngIf="menuItem.children"></span>
                                        </a>
                                        <!-- External Tab Link -->
                                        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extTabLink'">
                                            {{ menuItem.title }}
                                            <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
                                            <span class="sub-arrow" *ngIf="menuItem.children"></span>
                                        </a>
                                        <!-- 2nd Level Menu -->
                                        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
                                            <li *ngFor="let childrenItem of menuItem.children">
                                                <!-- Sub -->
                                                <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" 
                                                    (click)="toggletNavActive(childrenItem)">
                                                    {{ childrenItem.title | translate }}
                                                    <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
                                                    <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                                                </a>
                                                <!-- Link -->
                                                <a [routerLink]="childrenItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                                                    *ngIf="childrenItem.type === 'link' ">
                                                    {{ childrenItem.title | translate }}
                                                    <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
                                                    <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                                                </a>
                                                <!-- External Link -->
                                                <a href="{{ childrenItem.path }}"
                                                    *ngIf="childrenItem.type === 'extLink' ">
                                                    {{ childrenItem.title | translate }}
                                                    <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
                                                    <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                                                </a>
                                                <!-- External Tab Link -->
                                                <a href="{{ childrenItem.path }}" target="_blank"
                                                    *ngIf="childrenItem.type === 'extTabLink' ">
                                                    {{ childrenItem.title | translate }}
                                                    <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
                                                    <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                                                </a>
                                                <!-- 3rd Level Menu -->
                                                <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
                                                    <li *ngFor="let childrenSubItem of childrenItem.children">
                                                        <!-- Link -->
                                                        <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                                                            *ngIf="childrenSubItem.type === 'link' ">
                                                            {{ childrenSubItem.title | translate }}
                                                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                                                        </a>
                                                        <!-- External Link -->
                                                        <a href="{{ childrenSubItem.path }}"
                                                            *ngIf="childrenSubItem.type === 'extLink' ">
                                                            {{ childrenSubItem.title | translate }}
                                                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                                                        </a>
                                                        <!-- External Tab Link -->
                                                        <a href="{{ childrenSubItem.path }}" target="_blank"
                                                            *ngIf="childrenSubItem.type === 'extTabLink' ">
                                                            {{ childrenSubItem.title | translate }}
                                                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col mega-box" *ngFor="let childrenItem of menuItem.children">
                                                        <div class="link-section">
                                                            <div class="menu-title">
                                                                <h5>
                                                                    {{ childrenItem.title | translate }}
                                                                    <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                                                                </h5>
                                                            </div>
                                                            <div class="menu-content opensubmegamenu">
                                                                <ul *ngIf="childrenItem.children">
                                                                    <li *ngFor="let childrenSubItem of childrenItem.children">
                                                                        <!-- Sub -->
                                                                        <a href="javascript:void(0)" *ngIf="childrenSubItem.type === 'sub'">
                                                                            {{ childrenSubItem.title | translate }}
                                                                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                                                                        </a>
                                                                        <!-- Link -->
                                                                        <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                                                                            *ngIf="childrenSubItem.type === 'link' ">
                                                                            {{ childrenSubItem.title | translate }}
                                                                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                                                                        </a>
                                                                        <!-- External Link -->
                                                                        <a href="{{ childrenSubItem.path }}"
                                                                            *ngIf="childrenSubItem.type === 'extLink' ">
                                                                            {{ childrenSubItem.title | translate }}
                                                                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                                                                        </a>
                                                                        <!-- External Tab Link -->
                                                                        <a href="{{ childrenSubItem.path }}" target="_blank"
                                                                            *ngIf="childrenSubItem.type === 'extTabLink' ">
                                                                            {{ childrenSubItem.title | translate }}
                                                                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>                          
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>